<div *ngIf="!isConsentGiven && !isConsentRejected" class="cookie-consent">
    <div class="purple-bar"></div>
    <p i18n="Desc tracking consent with Privacy Policy dialog@@label.dialogTrackingConsentWithPrivacyDesc">
        We use cookies to improve your experience. Visit 
        <a href="https://friskusas.no/privacy-policy" 
           class="link text-nowrap" 
           target="_blank">privacy policy</a> 
        section to see the details we gather. Do you accept?
    </p>
    <button mat-raised-button class="mt-2 mr-2 text-center" color="primary" type="button" (click)="onConsentGiven()" i18n="Accept button@@button.acceptFirstUpper">Accept</button>
    <button mat-stroked-button
        color="primary"
        type="button"
        class="mt-2 ml-2 text-center"
        (click)="onConsentRejected()">
        <span i18n="Reject button@@button.reject">Reject</span>
    </button>
</div>