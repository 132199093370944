<friskus-header [userInfo]="userInfo"
  [user]="user"
  [messengerUserData]="messengerUserData"
  (logout)="onLogout()"
  (menuOpened)="blockBodyScrolling($event)"></friskus-header>
<friskus-progress-bar></friskus-progress-bar>
<div class="app-container"  cdk-scrollable
  [ngClass]="{'is-annoucement': isAnnouncement}">
  <router-outlet *ngIf="isOnline;else isOffline"></router-outlet>

  <ng-template #isOffline>
    <div>
      <h3 i18n="@@offlineMessage">There are no Internet connection</h3>
    </div>
  </ng-template>
</div>

<friskus-help-beacon></friskus-help-beacon>

<!-- Tracking consent -->
<div class="tracking-consent-overlay">
  <friskus-tracking-consent-overlay></friskus-tracking-consent-overlay>
</div>

<!-- Survey disabled -->
<!-- <div class="survey-overlay">
  <friskus-survey-popup-overlay></friskus-survey-popup-overlay>
</div> -->
<friskus-footer></friskus-footer>
