import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "@environments/environment";
import { CookiesService } from "@services/cookies/cookies.service";

const CLARITY_CONSENT_COOKIE_NAME = 'clarityConsent';

@Component({
    selector: 'friskus-tracking-consent-overlay',
    templateUrl: './tracking-consent-overlay.component.html',
    styleUrls: ['./tracking-consent-overlay.component.scss']
})

export class TrackingConsentOverlayComponent implements OnInit, OnDestroy {
    public isConsentGiven = false;
    public isConsentRejected = false;
    
    constructor (
        private cookiesService: CookiesService,
    ) {}

    ngOnInit(): void {
        const consent = this.getClarityConsent();
        if (consent === 'true') {
            this.isConsentGiven = true;
            this.loadClarity();
        } else if (consent === 'false') {
            this.isConsentRejected = true;
        }
    }

    ngOnDestroy(): void {}

    loadClarity() {
        if (!document.getElementById('clarity-script')) {
            const script = document.createElement('script');
            script.id = 'clarity-script';
            script.type = 'text/javascript';
            script.text = `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${environment.clarityProjectId}");
            `;
            document.head.appendChild(script);
        }
    }

    getClarityConsent() {
        return this.cookiesService.getItem(document.cookie, CLARITY_CONSENT_COOKIE_NAME);
    }

    onConsentGiven() {
        const domain = '.friskus.com';
        document.cookie = this.cookiesService.setItem(document.cookie, CLARITY_CONSENT_COOKIE_NAME, 'true', '/', domain);
        this.isConsentGiven = true;
        this.isConsentRejected = false;
        this.loadClarity();
    }

    onConsentRejected() {
        const domain = '.friskus.com';
        document.cookie = this.cookiesService.setItem(document.cookie, CLARITY_CONSENT_COOKIE_NAME, 'false', '/', domain);
        this.isConsentRejected = true;
        this.isConsentGiven = false;
    }  
}